import onboard from "./onboard";

const state = {
  address: null,
  web3: null,
  walletname: null,
  network: null,
  contracts: null,
  planetIds: null,
  darkTheme:
    localStorage.getItem("DarkMode") == "true"
      ? true
      : window.matchMedia("(prefers-color-scheme: dark)").matches,
};

const _networks = {
  1: "Mainnet",
  5: "Goerli",
  43114: "Avax",
  43113: "Fuji",
};

const getters = {
  userAddress: (state) => state.address,
  web3: (state) => state.web3,
  getNetwork: (state) => _networks[state.network],
  getNetworkId: (state) => state.network,
  contracts: (state) => state.contracts,
  planetIds: (state) => state.planetIds,
  darkTheme: (state) => state.darkTheme,
};

const actions = {
  setAddress({ commit }, address) {
    commit("setAddress", address);
  },
  exit({ commit }) {
    commit("removeAddress");
  },
  setWeb3({ commit }, newWeb3) {
    commit("setWeb3", newWeb3);
  },
  setWallet({ commit }, wallet) {
    commit("setWallet", wallet);
  },
  setNetwork({ commit }, network) {
    commit("setNetwork", network);
  },
  setContracts({ commit }, contracts) {
    commit("setContracts", contracts);
  },
  setPlanetIds({ commit }, planetIds) {
    commit("setPlanetIds", planetIds);
  },
  toggleTheme({ commit }) {
    commit("toggleTheme");
  },
};

const mutations = {
  setAddress: async (state, a) => {
    await onboard.connectWallet()
    state.address = a;
  },
  removeAddress: async (state) => {
    const [primaryWallet] = onboard.state.get().wallets;
    await onboard.disconnectWallet({ label: primaryWallet.label });
    localStorage.removeItem("selectedWallet");
    state.address = null;
  },
  setWeb3: (state, a) => (state.web3 = a),
  setWallet: (state, a) => (state.walletname = a),
  setNetwork: (state, a) => (state.network = a),
  setContracts: (state, a) => (state.contracts = a),
  setPlanetIds: (state, a) => (state.planetIds = a),
  toggleTheme: (state) => {
    state.darkTheme = !state.darkTheme;
    localStorage.setItem("DarkMode", state.darkTheme);
  },
};

export let networks = _networks;

export default {
  state,
  getters,
  actions,
  mutations,
};
