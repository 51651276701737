/**
 * This file includes the contract informations
 * such as abi's, addresses and constants imported from contracts folder.
 * Import any contract to use from here.
 * DELETE USELESS INFO
 **/

/*********************************** DELETE USELESS INFO *******************************/

import store from "../store";
import axios from "axios";

import Portal from "./abis/Portal.json";
import gAVAX from "./abis/gAVAX.json";
import Swap from "./abis/Swap.json";
import LP from "./abis/LPToken.json";

const axiosConfig = {
  baseURL: "https://api-avax-1efd0eddc708.herokuapp.com/api/avax/",
  timeout: 30000,
};
const ax = axios.create(axiosConfig);

export default async function catchContracts() {
  var _contracts = {};
  var _planets = [];
  var web3 = store.getters["web3"];
  try {
    _planets = (await ax.get("portal/overview")).data.data.planets;
    if (web3) {
      // we have a connection lets use real data
      const network = store.getters["getNetwork"];
      if (network == "Avax") {
        // construct contracts
        _contracts = {
          Portal: new web3.eth.Contract(
            Portal.abi,
            web3.utils.toChecksumAddress(Portal.address)
          ),
          gAVAX: new web3.eth.Contract(
            gAVAX.abi,
            web3.utils.toChecksumAddress(gAVAX.address)
          ),
        };

        // construct Portal data
        _planets = await _contracts.Portal.methods.getIdsByType(5).call();
      } else {
        console.log("not Avax");
      }
    }
  } catch (err) {
    console.log(err);
  }
  try {
    _planets = (await ax.get("portal/overview")).data.data.planets;
  } catch (err) {
    console.log(err);
  }
  store.commit("setContracts", _contracts);
  store.commit("setPlanetIds", _planets);
}

export function getWithdrawalPool(address) {
  var web3 = store.getters["web3"];
  if (web3) {
    return new web3.eth.Contract(
      Swap.abi,
      web3.utils.toChecksumAddress(address)
    );
  }
}

export function getWPToken(address) {
  var web3 = store.getters["web3"];
  if (web3) {
    return new web3.eth.Contract(LP.abi, web3.utils.toChecksumAddress(address));
  }
}
