<template>
    <a
        rel="noopener noreferrer"
        target="_blank"
        :href="network.href" hreflang="en"
      >
        <div :class="{'circle-container': background != null, 'anim': anim != null, 'centerize': true}">
          <img :src="network.darkModeSrc != null ? (darkMode ? network.darkModeSrc : network.imgSrc) : network.imgSrc" :height="height" :class="network.imgClass" :alt="network.imgAlt" />
        </div>
        <p v-if="network.text != null" class="social"><b>{{network.text}}</b></p>
    </a>
</template>

<script>
export default {
    props: ["network","height", "background", "anim", "darkMode"]
}
</script>

<style scoped>
.circle-container {
  background: #f2faff;
  height: 56px;
  margin-right: 20px;
  width: 56px;
  border-radius: 50%;
}
.anim {
  padding: 0 12px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
.anim:hover {
  transform: scale(0.9);
}
p.social {
  margin-left: 10px;
  font-size: 20px;
}

/* Dark Mode on */
.DarkMode .circle-container {
  background: #383838;
}

@media screen and (max-width: 819px) {
    .circle-container {
        height: 42px;
        width: 42px;
    }
    .social-container {
        width: 140px;
    }
    p.social {
        margin-left: 3px;
        font-size: 14px;
    }
}

</style>