import Vue from "vue";
import VueRouter from "vue-router";
const Portal = () => import("@/components/Portal");
const Pools = () => import("@/components/Pools");
const DWP = () => import("@/components/DWP");

Vue.use(VueRouter);
let routes = [
  {
    path: "/portal/:id",
    name: "Portal",
    component: Portal,
  },
  {
    path: "/pool/:id",
    name: "DWP",
    component: DWP,
    props: true,
  },
  {
    path: "/*",
    name: "Pools",
    component: Pools,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
});

export default router;
