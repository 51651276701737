<template>
  <div class="DarkModer">
    <label class="switch">
      <input type="checkbox" :checked="darkTheme" @input="toggleTheme" />
      <span class="slider round"></span>
    </label>
    <ImageVue
      class="moonImage DarkModeImage"
      src="icons/moon.png"
      size="11px"
    ></ImageVue>
    <ImageVue
      class="sunImage LightModeImage"
      src="icons/sun.png"
      size="15px"
    ></ImageVue>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ImageVue from "@/components/utils/ImageVue";
export default {
  computed: {
    ...mapGetters({ darkTheme: "darkTheme" }),
  },
  methods: {
    ...mapActions(["toggleTheme"]),
  },
  components: { ImageVue },
};
</script>

<style scoped>
.DarkModer {
  z-index: 1001;
  padding: 0;
  position: relative;
  margin: 0 12px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  background-color: rgba(21, 21, 21, 0.767);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3d3d3d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2d2e2e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: rgb(119, 119, 119);
}
.moonImage {
  position: absolute;
  top: 8px;
  left: 5px;
  opacity: 0.5;
}
.sunImage {
  position: absolute;
  top: 5px;
  left: 27px;
  opacity: 0.9;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>