var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Line",style:({
        width: _vm.vertical !=null ? _vm.width : _vm.length,
        height: _vm.vertical !=null ? _vm.length : _vm.width,
        backgroundColor: _vm.color,
        left: _vm.left != null ? 0 : 'none',
        right: _vm.right != null ? 0 : 'none',
        top: _vm.top != null ? 0 : 'none',
        bottom: _vm.bottom != null ? 0 : 'none',
    })})
}
var staticRenderFns = []

export { render, staticRenderFns }