<template>
    <div class="Line" :style="{
            width: vertical !=null ? width : length,
            height: vertical !=null ? length : width,
            backgroundColor: color,
            left: left != null ? 0 : 'none',
            right: right != null ? 0 : 'none',
            top: top != null ? 0 : 'none',
            bottom: bottom != null ? 0 : 'none',
        }">

    </div>
</template>

<script>
export default {
    props: ["width","length", "color", "vertical", "left", "right", "top", "bottom"]
}
</script>


<style scoped>
.Line {
    background-color: #2F2450;
    position: absolute;
    margin: auto;
}

.DarkMode .Line {
    background-color: rgba(236, 229, 255, 0.5);
}

</style>