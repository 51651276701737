/**
 * This file includes common libraries and settings for them; such as axios, web3, bignumber.js.
 * import the libraries from here to use
 */

import Notify from "bnc-notify";
import axios from "axios";

export const notify = Notify({
  dappId: "7ca10abc-cd26-4616-a274-4fa6201e5039", // [String] The API key
  networkId: 1, // [Integer] The Ethereum network ID your Dapp uses.
  darkMode: true,
  desktopPosition: "bottomRight",
  mobilePosition: "bottom",
});

export function notifyHandler(hash) {
  let { emitter } = notify.hash(hash);
  emitter.on("all", (transaction) => ({
    onclick: () =>
      window.open(
        `https://snowtrace.io/tx/${transaction.hash}`,
        "noopener norefferer"
      ),
    autoDismiss: 10000,
  }));
}

export function notifyNotification(message, type = "pending") {
  let notificationObject = {
    eventCode: "notification",
    type: type,
    message: message,
  };
  return notify.notification(notificationObject);
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getPrice(token) {
  if (token == "avax") {
    const data = (
      await axios.get("https://api.coingecko.com/api/v3/coins/avalanche-2")
    ).data.market_data;
    return { price: data.current_price.usd, change: data.price_change_24h };
  }
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
