import Vue from "vue";
import Web3 from "web3";
import Onboard from "@web3-onboard/core";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import gnosisModule from '@web3-onboard/gnosis'
import trezorModule from '@web3-onboard/trezor'

import store from "../index";
import catchContracts from "@/contracts";
import { timeout } from "@/utils/common.js";

import logo from "./data/logo.svg";
import icon from "./data/icon.svg";
import core_icon from "./data/core.svg";


const avaCore = {
  label: "Avalanche",
  injectedNamespace: "avalanche",
  checkProviderIdentity: ({ provider }) => {
    if (!!provider && provider["isAvalanche"]) {
      Vue.notify({
        group: "notif",
        title: "Conflicting Wallets",
        text: "Disable Avalanche Core wallet to connect to Metamask", 
        type: 'error',
      });
      return true;
    } else {
      return false;
    }
  },
  // A method that returns a string of the wallet icon which will be displayed
  getIcon: async () => core_icon,
  // Returns a valid EIP1193 provider. In some cases the provider will need to be patched to satisfy the EIP1193 Provider interface
  getInterface: () => ({
    provider: window.avalanche,
  }),
  // A list of platforms that this wallet supports
  platforms: ["desktop"],
};
const trezor = trezorModule({
  email: 'admin@geode.fi',
  appUrl: 'https://www.geode.fi/',
  customNetwork:  {
    networkId: 43114
  }
})
const gnosis = gnosisModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true });
const injected = injectedModule({
  custom: [avaCore],
  filter: {
    [ProviderLabel.Core]: false,
  },
});

const onboard = Onboard({
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
  wallets: [injected, coinbaseWalletSdk, gnosis, trezor],
  chains: [
    {
      id: "0xa86a",
      token: "AVAX",
      label: "Avalanche Network",
      rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    },
  ],
  appMetadata: {
    name: "Geode",
    icon: icon,
    logo: logo,
    description: "Contract Owned Liquid Staking",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" },
    ],
  },
});
const autoConnect = async function () {
  const previouslyConnectedWallets = JSON.parse(
    window.localStorage.getItem("connectedWallets")
  );

  if (previouslyConnectedWallets) {
    await onboard.connectWallet({
      autoSelect: { label: previouslyConnectedWallets[0], disableModals: true },
    });
    await timeout(1000);
    Vue.notify({
      group: "notif",
      title: "Connected!",
      text: `Detected a previously connected wallet: ${previouslyConnectedWallets[0]} `, 
      type: 'success',
    });
  }
};
autoConnect();

const walletsSub = onboard.state.select("wallets");
walletsSub.subscribe(async (wallets) => {
  //localStorage
  const connectedWallets = wallets.map(({ label }) => label);
  window.localStorage.setItem(
    "connectedWallets",
    JSON.stringify(connectedWallets)
  );
  // chain
  store.commit("setNetwork", parseInt(wallets[0].chains[0].id, 16));
  if (parseInt(wallets[0].chains[0].id, 16) != 43114)
    await onboard.setChain({ chainId: "0xa86a" });

  // address
  store.commit("setAddress", wallets[0].accounts[0].address);

  // setup wallet
  let W3 = new Web3(wallets[0].provider);
  window.web3 = W3;
  store.commit("setWeb3", W3);
  await catchContracts();
});

window.onboard = onboard;
export default onboard;
