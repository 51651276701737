
/<template>
  <div class="animation-wrapper">
    <div class="particle particle-1"></div>
    <div class="particle particle-2"></div>
    <div class="particle particle-4"></div>
    <div class="background-gradient" id="purple"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.background-gradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 200vw;
  height: 200vh;
  transform: translate(-50vw, -100vh);
  z-index: 0;
  transition: 0.3s ease-in-out;
}

#green {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(53, 75, 62, 0.17) 0,
    rgba(255, 255, 255, 0) 100%
  );
}
.DarkMode #green {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(53, 75, 62, 0.25) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

#purple {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(135, 97, 255, 0.2) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

.DarkMode #purple {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(76, 22, 251, 0.07) 0,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>

<style lang="sass" scoped>
@import "@/style/glow.scss"
</style>
