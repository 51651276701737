<template >
  <button
    :class="{ 'Button whiter flex-row': whiteText, Button: !whiteText }"
    :style="{
      backgroundColor: fill ? `#${color}` : 'transparent',
      boxShadow: `1px 1px 20px #${color}80`,
      border: `2px solid #${color}`,
      width: stretch ? '100%' : '',
    }"
    @click="$emit('clicked')"
    :disabled="disabled"
  >
    <ImageVue v-if="icon" :src="icon" :size="'20px'" />
    <div v-if="icon" class="gap-left"></div>
    <ImageVue
      :src="'dynamic/loading_white.svg'"
      class="loading"
      size="30px"
      v-if="loading"
    />
    <a class="p" :style="{ color: textColor }" v-else> {{ text }}</a>
  </button>
</template>
<script>
import ImageVue from "./ImageVue";
export default {
  props: {
    icon: String,
    link: String,
    text: String,
    color: String,
    textColor: String,
    fill: Boolean,
    disabled: Boolean,
    stretch: Boolean,
    whiteText: Boolean,
    loading: Boolean,
  },
  components: { ImageVue },
};
</script>
<style scoped>
.whiter > a {
  color: white;
}
.Button {
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 6px;
  /**border: 2px solid #18aaa1;**/
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.Button:hover {
  transform: scale(0.95);
}
.Button:disabled,
.Button:disabled:hover {
  opacity: 0.6;
  transform: scale(1);
  cursor: not-allowed !important;
}
</style>