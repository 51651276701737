<template>
    <Transition name="fade">
        <div v-if="show" class="PopupMain centerize">
            <div class="BackgroundCover" @click="Close()"></div>
            <div class="Popup">
                <div class="ImageContainer">
                    <ImageVue
                    :src="'logos/geode_black.svg'"
                    size="180px"
                    />
                </div>
                <Edge width="0.6px" length="85%" color="black" left right></Edge>
                <br/>
                <div class="CardsContainer">
                    <div v-for="wallet in wallets" :key="wallet.name" class="WalletCard" @click="ConnectWallet(wallet.id)">
                        <ImageVue :src="wallet.imgSrc" size="60%"/>
                        <p>{{wallet.name}}</p>
                    </div>
                </div>
                <ButtonVue
                    class="ConnectButton"
                    text="Connect"
                    :color="'7755dd'"
                    fill
                    :whiteText="true"
                    @click.native="Connect"
                    />
            </div>
        </div>
    </Transition>
</template>

<script>
import ImageVue from '@/components/utils/ImageVue.vue';
import Edge from '@/components/utils/edge.vue';
import { mapActions } from "vuex";
import ButtonVue from '@/components/utils/ButtonVue.vue';

export default {
    components: { ImageVue, Edge, ButtonVue },
    props: ["show"],
    data(){return {
        wallets: [
            {
                id: 0,
                name: "Wallet",
                imgSrc: "logos/wallet.png"
            },
            {
                id: 1,
                name: "Avalanche Core",
                imgSrc: "logos/corewallet.svg"
            },
        ]
    }},
    methods: {
        ...mapActions(["setAddress"]),
        async ConnectWallet(walletNo){  //0 for metamask 1 for avalanche core wallet
            this.setAddress(walletNo);
        },
        Close() {
            this.$emit("close", null);
        }
    },
}
</script>

<style scoped>
.BackgroundCover {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 15;
    background: rgba(0, 0, 0, 0.25);
}
.CardsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 350px;
    flex-wrap: wrap;
}
.ConnectButton {
    margin: auto;
    margin-top: 10px;
}
.ImageContainer {
    margin: 5px;
    margin-bottom: 10px;
    text-align: left;
}
.PopupMain {
    position: fixed;
    left: 0;
    top: 0;
  
}
.PopupMain p {
    color: black;
    margin: 0;
}
.Popup {
    position: absolute;
    text-align: center;
    z-index: 16;
    padding: 10px;
    padding-bottom: 20px;
    width: 350px;
    /*height: 220px;*/
    background: white;
    border-radius: 16px;
}
.WalletCard {
    padding: 20px 25px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    cursor: pointer
}
.WalletCard:hover {
    background-color: rgba(221, 221, 221, 0.336);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>