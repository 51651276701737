<template>
  <div id="app" :class="{ DarkMode: darkTheme }">
    <notifications position="top right" group="notif" classes="my-notification"/>
    <div class="App-container">
      <transition v-if="domIsReady" name="fade" mode="out-in">
        <Navbar class="Navbar" />
      </transition>
      <transition v-if="domIsReady" name="fade" mode="out-in">
        <Background class="Background" />
      </transition>
      <transition v-else name="fade" mode="out-in">
        <div class="loadWrapper">
          <ImageVue :src="'dynamic/loading.svg'" class="loading" />
        </div>
      </transition>
      <transition v-if="domIsReady" name="fade" mode="out-in">
        <router-view :mobile="mobile" class="Page" />
      </transition>
      <transition v-else name="fade" mode="out-in">
        <div class="loadWrapper">
          <ImageVue :src="'dynamic/loading.svg'" class="loading" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/Navbar";
import Background from "@/components/utils/Background";
import ImageVue from "./components/utils/ImageVue";
import { timeout } from "@/utils/common.js";
import catchContracts from "@/contracts";

const breakpoint = 820;
export default {
  name: "App",
  components: {
    Navbar,
    Background,
    ImageVue,
  },
  data() {
    return {
      domIsReady: false,
      mobile: window.innerWidth < breakpoint,
    };
  },
  computed: {
    ...mapGetters({ darkTheme: "darkTheme" }),
  },
  async mounted() {
    var self = this;
    document.onreadystatechange = async () => {
      if (document.readyState == "complete") {
        await catchContracts();
        await timeout(1000);
        self.domIsReady = true;
      }
    };
  },
};
</script>

<style>
@import url("style/global.css");
@import url("style/animations.css");

.App-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 144px;
  height: 100%;
  min-height: calc(100vh - 114px);
  width: 100%;
  min-width: calc(100vw - 114px);
}
.Page {
  z-index: 2;
}

.Navbar {
  opacity: 1;
  position: sticky;
  position: -webkit-sticky;
  z-index: 200;
  top: 0;
  z-index: 3;
}

.loadWrapper {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  z-index: 100;
}

.loading {
  padding: 0;
  max-height: 250px;
  max-width: 250px;
  z-index: 1;
}
.Background {
  z-index: 0;
}

.my-notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
}
  .my-notification.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }

  .my-notification.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  .my-notification.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
@import url("style/theme.css");
@import url("style/responsive.css");
</style>