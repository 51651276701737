<template>
  <img
    class="image"
    v-bind:style="{ width: size, height: height }"
    v-bind:src="publicPath + 'assets/' + src"
    :alt="alt ? alt : 'icon'"
  />
</template>

<script>
export default {
  props: { size: String, src: String, alt: String, height: String },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped>
.image {
  object-fit: contain;
}
</style>
