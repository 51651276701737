<template>
  <span :class="scrolled ? 'scrolledBar' : 'Navbar '">
    <div :class="{ Bar: !mobile, MobileBar: mobile }">
      <a href="https://www.geode.fi/">
        <ImageVue
          class="LightModeImage"
          :src="this.white ? 'logos/geode_black.svg' : 'logos/geode_white.svg'"
          :size="mobile ? '180px' : '200px'"
          style="padding-left: 5vw"
        />
        <ImageVue
          class="DarkModeImage"
          src="logos/geode_white.svg"
          :size="mobile ? '180px' : '200px'"
          style="padding-left: 5vw"
        />
      </a>
      <div v-if="!mobile" class="align-vertically content-center"></div>
      <div
        v-if="!mobile"
        class="content-right align-vertically"
        style="padding-right: 5vw"
      >
        <!-- <router-link :to="{ path: '/portal' }"
          ><div :class="{ link: white, 'link white': !white }">Portal</div>
        </router-link>
        <router-link :to="{ path: '/pools' }"
          ><div :class="{ link: white, 'link white': !white }">
            DWPs
          </div></router-link
        > -->
        <SocialMedia
          v-for="network in networks"
          :darkMode="!white"
          :key="network.name"
          :network="network"
          height="22px"
          anim
        ></SocialMedia>
        <DarkmodeSwitch class="DarkmodeSwitch link"></DarkmodeSwitch>
        <Button
          icon="logos/Avax.png"
          :text="
            userAddress
              ? userAddress.slice(0, 6) + '...' + userAddress.slice(-4)
              : 'Connect'
          "
          :color="'7755dd'"
          :fill="white"
          :whiteText="true"
          @click.native="Connect"
        />
      </div>
      <div class="align-vertically content-right toggle-button" v-else>
        <button
          :class="'align-vertically align-down ' + white ? 'gray' : ''"
          @click="ToogleMobileMenu"
        >
          <ImageVue
            class="LightModeImage"
            :src="
              white ? 'dynamic/toggler_dark.svg' : 'dynamic/toggler_white.svg'
            "
            :size="'23px'"
          />
          <ImageVue
            class="DarkModeImage"
            src="dynamic/toggler_white.svg"
            :size="'23px'"
          />
        </button>
      </div>
    </div>
    <div
      v-if="toggle"
      class="dark-background-on-menu"
      @click="ToogleMobileMenu"
    ></div>
    <Transition>
      <div v-if="toggle" class="myLinks">
        <DarkmodeSwitch class="DarkmodeSwitch link"></DarkmodeSwitch>
        <!-- <router-link :to="{ path: '/portal' }"
          ><div :class="{ 'link white': true }">Portal</div>
        </router-link>
        <router-link :to="{ path: '/pools' }"
          ><div :class="{ 'link white': true }">DWPs</div></router-link
        > -->
        <br />
        <div class="flex-row">
          <SocialMedia
            v-for="network in networks"
            :darkMode="true"
            :key="network.href"
            :network="network"
            height="24px"
            anim
          ></SocialMedia>
        </div>
        <div class="flex-row" style="padding: 20px">
          <Button
            icon="logos/Avax.png"
            :text="
              userAddress
                ? userAddress.slice(0, 6) + '...' + userAddress.slice(-4)
                : 'Connect'
            "
            :color="'354B3E'"
            :fill="true"
            textColor="#d8d8d8"
            @click.native="Connect"
          />
        </div>
      </div>
    </Transition>
    <WalletsPopup v-bind:show="walletsPopup" v-on:close="walletsPopup=false"></WalletsPopup>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/utils/ButtonVue";
import ImageVue from "@/components/utils/ImageVue";
import DarkmodeSwitch from "./darkModeSwitch";
import SocialMedia from "@/components/utils/SocialMedia.vue";
import WalletsPopup from "./WalletsPopup/index.vue";

const breakpoint = 820;
export default {
  components: { ImageVue, Button, DarkmodeSwitch, SocialMedia, WalletsPopup },
  data() {
    return {
      innerWidth: 0,
      scrolled: false,
      toggle: false,
      white: false,
      mobile: window.innerWidth < breakpoint,
      walletsPopup: false,
      networks: [
        {
          href: "https://twitter.com/geode_finance",
          imgAlt: "geode_twitter",
          imgSrc: "/assets/SocialMedia/twitter_gray.svg",
          darkModeSrc: "/assets/SocialMedia/twitter.svg",
        },
        {
          href: "https://discord.gg/RC8fTTuJtm",
          imgAlt: "geode_discord",
          imgSrc: "/assets/SocialMedia/discord_gray.svg",
          darkModeSrc: "/assets/SocialMedia/discord.svg",
        },
        {
          href: "https://medium.com/geode-finance",
          imgAlt: "geode_medium",
          imgSrc: "/assets/SocialMedia/medium_gray.svg",
          darkModeSrc: "/assets/SocialMedia/medium.svg",
        },
        {
          href: "https://docs.geode.fi/",
          imgAlt: "geode_docs",
          imgSrc: "/assets/SocialMedia/docs_gray.svg",
          darkModeSrc: "/assets/SocialMedia/docs_white.svg",
        },
        
      ],
    };
  },
  created: function () {
    this.innerWidth = window.innerWidth;
    this.toggle = false;
    this.white = !this.scrolled ? true : false;
  },
  mounted: async function () {
    this.toggle = false;
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters({ userAddress: "userAddress" }),
  },
  methods: {
    ...mapActions(["setAddress", "exit"]),
    async Connect() {
      this.userAddress ? await this.exit() : await this.setAddress(); //this.walletsPopup = true //;
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (currentScrollPosition > 30) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
      this.white = !this.scrolled ? true : false;
    },
    onResize() {
      this.mobile = window.innerWidth < breakpoint;
    },
    ToogleMobileMenu() {
      this.toggle = !this.toggle;
    },
  },
};
</script>
<style scoped lang="scss">
.scrolledBar,
.Navbar {
  z-index: 1000;
  padding: 60px 0px 10px 0px;
  transition: 0.3s ease-in-out;
}
.scrolledBar,
.Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
.Bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left center right";
  transition: 0.3s ease-in-out;
  width: 95%;
  max-width: 1800px;
}

.MobileBar {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left right";
  transition: 0.3s ease-in-out;
}
.scrolledBar {
  padding: 35px 0px 35px 0px;
}
.toggle-button {
  padding-right: 5vw;
}
.toggle-button > button {
  background: black;
}

.toggle-button > .gray {
  background: rgba(53, 75, 62, 0.1);
  height: 100%;
  border-radius: 4px;
}
/* mobile containers */
.myLinks {
  position: fixed;
  padding: 10px;
  width: 80vw;
  height: 100vh;
  z-index: 15;
  right: 0;
  top: 0;
  background: black;
}
.myLinks a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}
.myLinks h3 {
  display: inline;
}
.myLinks a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.dark-background-on-menu {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.DarkmodeSwitch {
  margin: 14px 16px;
}
@media screen and (max-width: 600px ) {
  .myLinks a {
    padding: 14px 5px;
  }
}
</style>